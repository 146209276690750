<template>
    <div class="index" style="background:#f4f3f3">
        <van-popup class="popup_top" v-model="show" round position="top" :style="{ padding: '0.6rem 1.09375rem 0.3rem'}" :close-on-click-overlay="clickoverlay" @click-overlay="overlayClick">
            <van-field
                style="background: #efefef;margin-bottom: .90625rem;"
                readonly
                clickable
                name="picker"
                :value="formDate.game"
                label="游戏："
                placeholder="点击选择游戏"
                @click="formDate.gameShowPicker = true"
                />
            <van-popup v-model="formDate.gameShowPicker" position="bottom" duration="0.15" get-container="body">
                <van-picker
                    show-toolbar
                    value-key="channelName"
                    :columns="formDate.games"
                    @confirm="onConfirm"
                    @cancel="formDate.gameShowPicker = false"
                />
            </van-popup>
            <van-row style="margin-bottom: .90625rem;">
                <van-col span="11"><van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.price_min" type="number" placeholder="价格最低" /></van-col>
                <van-col span="2" style="padding: .5625rem;color:#cccccc">-</van-col>
                <van-col span="11"><van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.price_max" type="number"  placeholder="价格最高" /></van-col>
            </van-row>
            <van-row style="margin-bottom: .90625rem;">
                <van-col span="11"><van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.lv_min" type="digit" placeholder="等级最低" /></van-col>
                <van-col span="2" style="padding: .5625rem;color:#cccccc">-</van-col>
                <van-col span="11"><van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.lv_max" type="digit"  placeholder="等级最高" /></van-col>
            </van-row>
            <van-row style="margin-bottom: .90625rem;">
                <van-col span="11"><van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.zs_lv_min" type="digit" placeholder="转生等级最低" /></van-col>
                <van-col span="2" style="padding: .5625rem;color:#cccccc">-</van-col>
                <van-col span="11"><van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.zs_lv_max" type="digit"  placeholder="转生等级最高" /></van-col>
            </van-row>
            <van-row  class="profession progroup" style="margin-bottom: .90625rem;"  gutter="15">
                <van-checkbox-group v-model="formDate.professionCheck">
                    <van-col span="6" v-for="item in formDate.checklist" :key="item.id">
                        <van-checkbox @click="professionChange" :name="item.name" :class="{checked: formDate.professionCheck.indexOf(item.name) > -1}">{{item.title}}</van-checkbox>
                    </van-col>
                </van-checkbox-group>
            </van-row>
            <van-row  class="profession" style="margin-bottom: .90625rem;"  gutter="15">
                <van-col span="12">
                    <van-field
                        style="background: #efefef;margin-bottom: .90625rem;"
                        readonly
                        name="picker"
                        :value="formDate.region"
                        label="大区："
                        placeholder="请选择"
                        @click="changeClick(1)"
                        />
                    <van-popup v-model="formDate.regionShowPicker" position="bottom" duration="0.15" get-container="body">
                        <van-picker
                            show-toolbar
                            value-key="regione_name"
                            :columns="formDate.regions"
                            @confirm="onConfirm"
                            @cancel="formDate.regionShowPicker = false"
                        />
                    </van-popup>
                </van-col>
                <van-col span="12">
                    <van-field
                        style="background: #efefef;margin-bottom: .90625rem;"
                        readonly
                        name="picker"
                        :value="formDate.server"
                        label="服务器："
                        placeholder="请选择"
                        @click="changeClick(2)"
                        />
                    <van-popup v-model="formDate.serverShowPicker" position="bottom" duration="0.15" get-container="body">
                        <van-picker
                            show-toolbar
                            value-key="server_name"
                            :columns="formDate.servers"
                            @confirm="onConfirm"
                            @cancel="formDate.serverShowPicker = false"
                        />
                    </van-popup>
                </van-col>
                
            </van-row>
            <!-- <van-row style="margin-bottom: .90625rem;">
                <van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.big_demon" type="digit" maxlength="2" placeholder="大魔神等级" />
            </van-row>
            <van-row style="margin-bottom: .90625rem;">
                <van-field style="background: #efefef;padding: .5625rem;" v-model="formDate.little_demon" type="digit" maxlength="2" placeholder="小魔神等级" />
            </van-row> -->
            <van-row  class="profession" style="margin-bottom: .90625rem;"  gutter="15">
                <van-col span="12"><van-button round type="default"  size="large" @click="reSet">重置选项</van-button></van-col>
                <van-col span="12"><van-button round color="linear-gradient(to right, rgb(74, 154, 247), rgb(1, 101, 218))"  size="large" @click="onSearch">搜索</van-button></van-col>
            </van-row>
        </van-popup>
        <div class="home_top_swiper">
            <div class="search_outside">
                <div class="back">
                    <van-icon @click="backClick" name="arrow-left" color="#fff"/>
                </div>
                <van-search
                    class="home_search"
                    v-model="key_word"
                    background="rgba(0,0,0,0)"
                    placeholder="请输入关键词搜索"
                    @search="onSearch"
                />
                <router-link to="/message">
                    <van-icon class="icon_search" name="envelop-o" :dot='true' color="#fff" />
                </router-link>
            </div>
        </div>
        <!-- <van-tabs class="home_tab" :ellipsis="false"> 
            <van-tab title="时光倒流">
            </van-tab>
            <van-tab title="魔神单职业">
            </van-tab>
            <van-tab  title="异兽单职业">
            </van-tab>
            <van-tab  title="火龙传奇">
            </van-tab>
            <van-tab  title="冰雪传奇">
            </van-tab>
        </van-tabs> -->
        <van-tabs class="public_tabs" v-model="tabbar_active">
            <van-tab>
                <template #title>
                    <img class="public_title_active" src="../../assets/images/buy_all_active.png" alt="">
                    <img class="public_title" src="../../assets/images/buy_all.png" alt="">
                </template>
            </van-tab>
            <van-tab>
                <template #title>
                    <img class="public_title_active" src="../../assets/images/buy_juese_active.png" alt="">
                    <img class="public_title" src="../../assets/images/buy_juese.png" alt="">
                </template>
            </van-tab>
            <van-tab>
                <template #title>
                    <img class="public_title_active" src="../../assets/images/buy_zhuangbei_active.png" alt="">
                    <img class="public_title" src="../../assets/images/buy_zhuangbei.png" alt="">
                </template>
            </van-tab>
            <van-tab>
                <template #title>
                    <img class="public_title_active" src="../../assets/images/buy_daoju_active.png" alt="">
                    <img class="public_title" src="../../assets/images/buy_daoju.png" alt="">
                </template>
            </van-tab>
            <van-tab>
                <template #title>
                    <img class="public_title_active" src="../../assets/images/buy_gongshi_active.png" alt="">
                    <img class="public_title" src="../../assets/images/buy_gongshi.png" alt="">
                </template>
            </van-tab>
        </van-tabs>
        <van-row class="filterColumn" style="background:#ffffff;font-size: .875rem;">
            <van-col span="6" @click="sortMeans(1)" :class="{ action: isActive==1 }">默认排序</van-col>
            <van-col span="6" @click="sortMeans(2)" :class="{ action: isActive==2 }">公示期</van-col>
            <van-col class="sort" @click="sortMeans(3)" :class="{ action_top: isActive==3 , action_bottom:isActive==4}" span="6">
                <span>价格</span>
                <!-- <van-icon class="top" name="play" />
                <van-icon class="bottom" name="play" /> -->
                <van-icon class="top" name="arrow-up" />
                <van-icon class="bottom" name="arrow-down"/>
            </van-col>
            <van-col span="6" @click="screenClick">
                <span style="vertical-align: middle;">筛选</span>
                <van-icon style="vertical-align: middle;" name="filter-o" />
            </van-col>
        </van-row>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                style="padding-bottom: 50px;background:#f4f3f3;"
                class="list_side"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                :immediate-check="loadcheck"
                @load="onLoad"
            >
                <van-cell v-for="item in list" :key="item.id">
                        <div class="index_list" @click="characterdetails(item.goods_id)">
                            <span class="index_list_l">
                                <img class="profilePhoto" :src="item.imgSrc">
                                <span class="index_list_c">
                                    <div class="index_list_c_t">{{item.channel_name}}\{{item.job}}\{{item.lv}}级</div>
                                    <div class="index_list_c_b">
                                        <span class="index_list_c_b_t" :class="{shenhezhong : item.status == 1,gongshiqi : item.status == 8,chushouqi : item.status == 2,xiajia : item.status == 7, shanchu: item.status == 4 || item.status == 5, yishouchu: item.status == 3}">{{item.status_name}}</span>
                                        <span class="index_list_c_b_b">{{item.surplus_time}}</span>
                                    </div>
                                </span>
                            </span>
                            <span class="index_list_r">
                                <div class="index_list_r_t">{{item.server_name}}</div>
                                <div class="index_list_r_b">￥{{item.price}}</div>
                            </span>
                        </div>
                    <!-- </router-link> -->
                </van-cell>
            </van-list>
        </van-pull-refresh>
        <van-overlay :show="dialogShow" class="notice">
            <div class="wrap">
                <div class="block_w">
                    <h3>公告</h3>
                    <van-icon name="cross" size="20" @click="noticeClose" />
                    <div class="wrap_c" v-html="noticeHtml" v-if="noticeType == 1"></div>
                    <div class="wrap_c" v-else>
                        <img :src="noticeSrc" alt="" style="width: 100%;">
                    </div>
                    <div class="login_btns wrap_b">
                        <van-checkbox v-model="dialogChecked">不再提示</van-checkbox>
                        <van-button round type="info" size="large" color="linear-gradient(to right, #4a9af7, #0165da)" @click="confirmClick">确认</van-button>
                    </div>
                </div>
            </div>
        </van-overlay>

    </div>
</template>
<script>
export default {
    name:'buyTel',
    data(){
        return {
            tabbar_active:'',
            show: false,
            key_word: '',
            profession:1,
            active:'home',
            isActive:1,
            loading: false,
            finished: false,
            error: false,
            formDate:{
                game: '',
                games: [],
                channel_id: '',
                gameShowPicker: false,
                price_min: '',
                price_max: '',
                lv_min: '',
                lv_max: '',
                zs_lv_min: '',
                zs_lv_max: '',
                region: '',
                regions: [],
                regione_id: '',
                regionShowPicker: false,
                server: '',
                servers: [],
                server_id: '',
                serverShowPicker: false,
                big_demon: '',
                little_demon: '',
                professionCheck: [],
                checklist: [
                    {
                        name: 'all',
                        title: '不限'
                    },{
                        name: '100',
                        title: '战士'
                    },{
                        name: '102',
                        title: '道士'
                    },{
                        name: '101',
                        title: '法师'
                    }
                ]
            },
            list: [],
            total: 0,
            page: 1,
            isSearch: false,
            loadcheck: false,
            refreshing: false,
            gameLinkList: [],
            dialogShow: false,
            dialogChecked: false,
            noticeHtml: '',
            clickoverlay: false,
            setKeyboard: false,
            noticeType: 1,
            noticeSrc: ''
        }
    },
    created() {
        //获取游戏渠道列表数据
        this.gameChannelList();
        //获取游戏版本列表
        this.getChannel();
    },
    mounted(){
        this.$nextTick(function () {
            window.addEventListener('focusin', () => {
                // var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // alert(resizeHeight);
                // 键盘弹出事件处理
                //this.$store.commit('setKeyboard', true);
                //alert('键盘弹起')
                this.setKeyboard = true;
            });
            window.addEventListener('focusout', () => {
                // 键盘收起事件
                //this.$store.commit('setKeyboard', false);
                // var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // alert('键盘关闭')
                setTimeout(() => {
                    this.setKeyboard = false;
                }, 200);
            });

        })
    },
    methods:{
        backClick() {
            let isRouter = localStorage.getItem('isRouter');
            if (this.isHas) {
                this.$router.push({
                    name: this.isHas
                });
            }else{
                if (isRouter == 'true' || window.history.length <= 2) {
                    this.$router.push({
                        name: 'Home'
                    });
                }else{
                    this.$router.back(-1);
                }
            }
        },
        characterdetails(goods_id){
            this.$router.push({
                name: 'characterDetails',
                query: {
                    channel_id:this.formDate.channel_id,
                    goods_id: goods_id,
                }
            });
        },
        //获取游戏版本列表
        getChannel() {
            this.$api.helper.getChannel().then(res => {
                //console.log(res);
                if (res.code == 200 && res.data.length > 0) {
                    this.formDate.games = res.data;
                    this.formDate.games.unshift({
                        channelName: '全部',
                        channelId: '',
                    });
                    //设置默认游戏选项
                    this.formDate.channel_id = this.formDate.games[0].channelId;
                    //请求公告
                    this.getNotice();
                    if (String(this.formDate.channel_id)) {
                        var newArr = this.formDate.games.filter(item => {
                            return item.channelId == this.formDate.channel_id;
                        });
                        this.formDate.game = newArr[0].channelName;
                        this.regionList();
                    }
                    //游戏头像展示
                    // this.gameLinkList = [];
                    // res.data.forEach((item) => {
                    //     item.name = item.channelName;
                    //     item.url = item.webUrl;
                    //     switch (item.channelId) {
                    //         case 0:
                    //             item.src = require('../../assets/images/more_heard1.png');
                    //             break;
                    //         case 5:
                    //             item.src = require('../../assets/images/more_heard4.png');
                    //             break;
                    //         case 6:
                    //             item.src = require('../../assets/images/more_heard2.png');
                    //             break;
                    //         case 9:
                    //             item.src = require('../../assets/images/more_heard3.png');
                    //             break;
                    //     }
                    //     this.gameLinkList.push(item);
                        
                    // });
                    this.loading = true;
                    this.onLoad();
                }
            });
        },
        //游戏名称列表
        gameChannelList() {
            this.$api.shopping.gameChannelList({
                dev: localStorage.getItem('ip')
            }).then(res => {
                if (res.code == 200 && res.data.length > 0) {
                    // this.formDate.games = res.data;
                    // //设置默认游戏选项
                    // this.formDate.channel_id = this.formDate.games[0].channel_id;
                    // //请求公告
                    // this.getNotice();
                    // if (String(this.formDate.channel_id)) {
                    //     var newArr = this.formDate.games.filter(item => {
                    //         return item.channel_id == this.formDate.channel_id;
                    //     });
                    //     this.formDate.game = newArr[0].channel_name;
                    //     this.regionList();
                    // }
                    //游戏头像展示
                    this.gameLinkList = [];
                    res.data.forEach((item) => {
                        item.name = item.channel_name;
                        item.url = item.web_url;
                        item.channel_id = item.channel_id;
                        switch (item.channel_id) {
                            case 0:
                                item.src = require('../../assets/images/more_heard1.png');
                                break;
                            case 5:
                                item.src = require('../../assets/images/more_heard4.png');
                                break;
                            case 6:
                                item.src = require('../../assets/images/more_heard2.png');
                                break;
                            case 9:
                                item.src = require('../../assets/images/more_heard3.png');
                                break;
                        }
                        this.gameLinkList.push(item);
                        
                    });
                    // this.loading = true;
                    // this.onLoad();
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //根据渠道获取大区列表
        regionList() {
            this.$api.shopping.regionList({
                channel_id: this.formDate.channel_id
            }).then(res => {
                if (res.code == 200 && res.data.length > 0) {
                    this.formDate.regions = res.data;
                    if (this.formDate.regione_id) {
                        var newArr = this.formDate.regions.filter(item => {
                            return item.regione_id == this.formDate.regione_id;
                        });
                        this.formDate.region = newArr[0].regione_name;
                        this.serverList();
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //根据渠道和大区获取区服
        serverList() {
            this.formDate.servers = [];
            this.$api.shopping.serverList({
                channel_id: this.formDate.channel_id,
                regione_id: this.formDate.regione_id
            }).then(res => {
                if (res.code == 200) {
                    for (var key in res.data) {
                        this.formDate.servers.push({
                            server_name: res.data[key].server_name,
                            server_id: res.data[key].server_id
                        });
                    }
                    if (this.formDate.server_id) {
                        var newArr = this.formDate.servers.filter(item => {
                            return item.server_id == this.formDate.server_id;
                        });
                        this.formDate.server = newArr[0].server_name;
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //大区和区服选择前提示
        changeClick(val) {
            if (val == 1) {
                if (this.formDate.game) {
                    this.formDate.regionShowPicker = true;
                }else{
                    this.$toast({
                        message: '请先选择游戏，再选大区',
                        duration: 1500
                    });
                }
            }else{
                if (this.formDate.region) {
                    this.formDate.serverShowPicker = true;
                }else{
                    this.$toast({
                        message: '请先选择大区，再选区服',
                        duration: 1500
                    });
                }
            }
        },
        //游戏选择确认
        onConfirm(val) {
            if (this.formDate.gameShowPicker) {
                //游戏渠道切换
                this.formDate.game = val.channelName;
                this.formDate.channel_id = val.channelId;
                this.formDate.gameShowPicker = false;
                this.formDate.region = '';
                this.formDate.server = '';
                this.formDate.regions = [];
                this.formDate.servers = [];
                this.regionList();
            } else if (this.formDate.regionShowPicker) {
                //大区切换
                this.formDate.region = val.regione_name;
                this.formDate.regione_id = val.regione_id;
                this.formDate.regionShowPicker = false;
                this.formDate.server = '';
                this.formDate.servers = [];
                this.serverList();
            } else {
                //区服切换
                this.formDate.server = val.server_name;
                this.formDate.server_id = val.server_id;
                this.formDate.serverShowPicker = false;
            }
        },
        //购买大厅列表
        goodsLists() {
            let type, order;
            type = this.isActive == 4 ? 3 : this.isActive;
            order = this.isActive == 3 ? 'asc' : 'desc';
            this.$api.shopping.goodsLists({
                channel_id: this.formDate.channel_id,
                type: type,
                order_sort: order,
                key_word: this.key_word,
                price_min: this.formDate.price_min,
                price_max: this.formDate.price_max,
                lv_min: this.formDate.lv_min,
                lv_max: this.formDate.lv_max,
                zs_lv_min: this.formDate.zs_lv_min,
                zs_lv_max: this.formDate.zs_lv_max,
                regione_id: this.formDate.regione_id,
                server_id: this.formDate.server_id,
                big_demon: this.formDate.big_demon,
                little_demon: this.formDate.little_demon,
                page: this.page,
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((item) => {
                            this.list.push(item);
                        });
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        if (this.page * 8  >= this.total) {
                            this.finished = true;
                        }
                        this.page ++;
                        //关掉错误提示
                        this.error = false;
                        this.list.forEach((item) => {
                            //获取上架中的状态样式
                            item.isActive = item.status == 2 ? false : true;
                            //获取头像
                            if (item.sex == 200) {
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                                }
                            }else{
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                                }
                            }
                        });
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //重置
        reSet() {
            this.formDate.game = '';
            this.formDate.channel_id = this.formDate.games[0].channel_id;
            this.formDate.price_min = '';
            this.formDate.price_max = '';
            this.formDate.lv_min = '';
            this.formDate.lv_max = '';
            this.formDate.zs_lv_min = '';
            this.formDate.zs_lv_max = '';
            this.formDate.region = '';
            this.formDate.regione_id = '';
            this.formDate.server = '';
            this.formDate.server_id = '';
            this.formDate.big_demon = '';
            this.formDate.little_demon = '';
            this.formDate.professionCheck = [];
        },
        //搜索
        onSearch(){
            this.show = false;
            this.getInitData(2);
            this.isSearch = true;
            this.finished = false;
            this.error = false;
            this.list = [];
            this.page = 1;
            this.loading = true;
            this.onLoad();
        },
        //列表加载
        onLoad(str) {
            //console.log(this.refreshing);
            if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
            }
            // 异步更新数据
            let type, order, job, data = {};
            type = this.isActive == 4 ? 3 : this.isActive;
            order = this.isActive == 3 ? 'asc' : 'desc';
            job = this.formDate.professionCheck.indexOf('all') > -1 ? '' : (this.formDate.professionCheck.length > 2 ? '' : this.formDate.professionCheck.join());
            if (this.isSearch) {
                data = {
                    channel_id: this.formDate.channel_id,
                    type: type,
                    order_sort: order,
                    key_word: this.key_word,
                    price_min: this.formDate.price_min,
                    price_max: this.formDate.price_max,
                    lv_min: this.formDate.lv_min,
                    lv_max: this.formDate.lv_max,
                    zs_lv_min: this.formDate.zs_lv_min,
                    zs_lv_max: this.formDate.zs_lv_max,
                    regione_id: this.formDate.regione_id,
                    server_id: this.formDate.server_id,
                    big_demon: this.formDate.big_demon,
                    little_demon: this.formDate.little_demon,
                    page: this.page,
                    job: job
                };
            }else{
                data = {
                    channel_id: this.formDate.channel_id,
                    type: type,
                    order_sort: order,
                    page: this.page,
                };
            }
            this.$api.shopping.goodsLists(data).then(res => {
                if(str=='clean'){
                    this.list = []
                }
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((item) => {
                            this.list.push(item);
                        });
                        this.total = res.data.total;
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        if (this.page * 8  >= this.total) {
                            this.finished = true;
                        }
                        this.page ++;
                        //关掉错误提示
                        this.error = false;
                        // console.log(this.list)
                        this.list.forEach((item) => {
                            //获取上架中的状态样式
                            
                            item.isActive = item.status == 2 ? false : true;
                            //获取头像
                            if (item.sex == 200) {
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                                }
                            }else{
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                                }
                            }
                        });
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //tab切换
        sortMeans(type){
            if(this.isActive==3&&type==3){
                this.isActive = 4;
            }else{
                this.isActive = type;
            }
            this.finished = false;
            this.error = false;
            this.list = [];
            this.page = 1;
            this.loading = true;
            this.onLoad('clean');
        },
        //下拉刷新
        onRefresh() {
            //清空列表数据
            this.finished = false;
            this.error = false;
            //重新加载数据
            //将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1;
            this.onLoad();
        },
        //获取筛选默认项
        getInitData(type) {
            let param = {}, job;
            job = this.formDate.professionCheck.indexOf('all') > -1 ? '' : (this.formDate.professionCheck.length > 2 ? '' : this.formDate.professionCheck.join());
            param = type == 1 ? {
                type: type
            } : {
                type: type,
                channel_id: this.formDate.channel_id,
                price_min: this.formDate.price_min,
                price_max: this.formDate.price_max,
                lv_min: this.formDate.lv_min,
                lv_max: this.formDate.lv_max,
                zs_lv_min: this.formDate.zs_lv_min,
                zs_lv_max: this.formDate.zs_lv_max,
                regione_id: this.formDate.regione_id,
                server_id: this.formDate.server_id,
                big_demon: this.formDate.big_demon,
                little_demon: this.formDate.little_demon,
                job: job
            };
            this.$api.shopping.getInitData(param).then(res => {
                if (res.code == 200) {
                    //console.log(res);
                    if (res.data.length > 0) {
                        let data = res.data[0];
                        if (type == 1) {
                            //this.formDate.channel_id = data.channel_id ? data.channel_id : this.formDate.games[0].channelId;
                            this.formDate.channel_id = data.channel_id;
                            this.formDate.game = data.channel_name;
                            this.formDate.price_min = data.price_min;
                            this.formDate.price_max = data.price_max;
                            this.formDate.lv_min = data.lv_min;
                            this.formDate.lv_max = data.lv_max;
                            this.formDate.zs_lv_min = data.zs_lv_min;
                            this.formDate.zs_lv_max = data.zs_lv_max;
                            this.formDate.big_demon = data.big_demon;
                            this.formDate.little_demon = data.little_demon;
                            this.formDate.regione_id = data.regione_id;
                            this.formDate.server_id = data.server_id;
                            this.formDate.professionCheck = data.job != '' ? data.job.split(',') : ['all'] ;
                            //this.gameChannelList();
                        }
                    }else{
                        this.formDate.channel_id = this.formDate.games[0].channelId;
                        this.formDate.game = this.formDate.games[0].channelName;
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //筛选点击
        screenClick() {
            this.show = true;
            this.getInitData(1);
        },
        //公告请求
        getNotice() {
            //获取本地保存的公告内容
            let noticehtml, noticecheck, timestamp, totaltime, nowtime;
            noticehtml = localStorage.getItem('noticeHtml');
            noticecheck = localStorage.getItem('noticeCheck');
            timestamp = localStorage.getItem('timestamp');

            this.$api.shopping.getNotice({
                // channel_id: this.formDate.channel_id
            }).then(res => {
                if (res.code == 200) {
                    if (JSON.parse(noticecheck)) {
                        if (res.data.noticeContent) {
                            if (res.data.noticeContent != noticehtml) {
                                this.dialogShow = true;
                            }else{
                                this.dialogShow = false;
                            }
                        }else{
                            this.dialogShow = false;
                        }
                    }else{
                        if (timestamp != 'null') {
                            totaltime = 6 * 60 * 60 * 1000;
                            nowtime = new Date().getTime();
                            if (nowtime - JSON.parse(timestamp) > totaltime) {
                                this.dialogShow = true;
                            }else{
                                this.dialogShow = false;
                            }
                        }else{
                            this.dialogShow = true;
                        }
                    }
                    this.noticeHtml = res.data.noticeContent;
                    this.noticeType = res.data.type;
                    if (res.data.type == 2) {
                        this.noticeSrc = process.env.VUE_APP_URL + '/' + res.data.noticeContent;
                        //console.log(this.noticeSrc);
                    }
                    localStorage.setItem('noticeHtml', res.data.noticeContent);
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //公告确认
        confirmClick() {
            this.dialogShow = false;
            let timestamp;
            if (!this.dialogChecked) {
                timestamp = new Date().getTime();
                localStorage.setItem('timestamp', timestamp);
            }
            localStorage.setItem('noticeCheck', this.dialogChecked);
        },
        //只能输入正整数
        getNumber(obj, key) {
            obj[key] = String(obj[key]).replace(/[^\d]/g, '');
        },
        //监听输入框只能输入正整数
        handlerInp() {
            // this.getNumber(this.formDate, 'lv_min');
            // this.getNumber(this.formDate, 'lv_max');
            // this.getNumber(this.formDate, 'zs_lv_min');
            // this.getNumber(this.formDate, 'zs_lv_max');
            // this.getNumber(this.formDate, 'big_demon');
            // this.getNumber(this.formDate, 'little_demon');
        },
        //点击遮罩层
        overlayClick() {
            if (this.setKeyboard) {
                this.show = true;
            }else{
                this.show = false;
            }
        },
        //关闭公告
        noticeClose() {
            this.dialogShow = false;
            let timestamp;
            if (!this.dialogChecked) {
                timestamp = new Date().getTime();
                localStorage.setItem('timestamp', timestamp);
            }
            localStorage.setItem('noticeCheck', this.dialogChecked);
        },
        professionChange(){
            // if(this.formDate.professionCheck.indexOf('all')!=-1){
            //     this.formDate.professionCheck = ['all']
            // }
            // if(this.formDate.professionCheck.indexOf('all')==-1)
        },
        //点击游戏logo
        getGameId(channel_id) {
            if (localStorage.getItem('token')) {
                this.$api.shopping.promote({
                    click_channel_id: channel_id
                }).then();
            }
        }
    },
    watch:{
        'formDate.professionCheck': function (val, oldVal) {
            if(val.indexOf('all')!=-1&&oldVal.indexOf('all')==-1){
                this.formDate.professionCheck = ['all']
            }else if(val.length>1&&val.indexOf('all')!=-1){
                this.formDate.professionCheck.splice(this.formDate.professionCheck.indexOf('all'),1)
            }
        },
    }
}
</script>
<style scoped>
.back {
    width: 26px;
    /* height: 40px; */
    position: absolute;
    top: 3px;
    left: 3%;
    font-size: 26px;
}
.index_list{
    background: #fff;
    padding: 2% 3%;
    border-radius: 5px;
}
.list_side .van-cell{
    background:#f4f3f3;
    padding: 1% 2%;
}
.list_side .van-cell::after{
    border: 0;
}
.my-swipe img{
    width: 100%;
    display: block;
}
.play_center{
    position: absolute;
    height:  1.9625rem;
    right: 0;
    bottom: 4%;
}
.home_top_swiper{
    position: relative;
    min-height: 40px;
    background-image: linear-gradient(#ff664c, #f84238);
}
.home_search{
    position: absolute;
    z-index: 100;
    width: 75%;
    top: 0;
    left: 12%;
}
.search_outside{
    width: 100%;
    position: absolute;
    z-index: 100;
}
.icon_search{
    margin: 8px 16px;
    position: absolute;
    right: 0;
    top: 2%;
    font-size: 26px;
}
.game_name .game_word{
    display: block;
    width: 100%;
    font-size:  0.75rem;
    line-height: 1.40625rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.more{
    padding-top: 2%;
    position: relative;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    padding-bottom: 2%;
}
.more::after{
    content: '';
    position: absolute;
    width: .725rem;
    height: 2.75rem;
    background: url('../../assets/images/hot.png') no-repeat;
    background-size: 100% 100%;
    top: 13px;
    left: -10px;
}
.more .game_name{
  width: 20%;
  padding: 0 1%;
}
.more .game_img{
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    /* height: 100%; */
}
.more .game_name img{
    width: 85%;
    display: block;
    margin: 7px auto;
    position: relative;
    border-radius: 50%;
}
.more .game_name .game_bg{
    width: 100%;
    position: absolute;
    top:  -4.5px;
}
.game_foreshow{
    display: block;
    padding: 23% 0;
    color: #969696;
}
 .notice-swipe {
    height: 40px;
    line-height: 40px;
}
.conclude_message_outside{
    background: #f4f3f3;
    padding: 2% 0;
}
.conclude_message{
    display: flex;
    background: #fae7e7;
    margin: 0 2%;
    border-radius: 7px;
}
.conclude_message .conclude_title{
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0;
    padding: 0 6px;
    border-right: 1px solid #313131;
}
.van-notice-bar {
    color: #ff4e00;
    background-color: #fae7e7;
    padding: 0 6px;
}
.public_title{
    height: 47px;
}
.public_title_active{
    height: 47px;
    display: none;
}







.van-search__action{
    color: #0072ff;
    padding-right: 20px;
}
.van-search__content{
    border-radius: 20px;
}
.more{
    display: flex;
    margin: 0 auto;
    width: 90%;
}
.sort{
    position: relative;
}
.sort .top{
    position: absolute;
    right: 23%;
    top: 25%;
    font-size: 11px;
    /* transform: rotate(-90deg); */
}
.sort .bottom{
    position: absolute;
    right: 23%;
    bottom: 23%;
    font-size: 11px;
    /* transform: rotate(90deg); */
}
.action{
    color: #ff4e00;
}
.action_top span{
    color: #ff4e00;
}
.action_bottom span{
    color: #ff4e00;
}
.action_top .top{
    color: #ff4e00;
}
.action_bottom .bottom{
    color: #ff4e00;
}
.filterColumn .van-col{
    line-height: 2.34375rem;
    background: #fbfbfb;
}


.popup_top .van-col{
    line-height: 24px;
    /* padding: .5625rem; */
}
.popup_top .profession button{
    height: 1.5625rem;
    color: #313131;
}
.isProfession{
    background: linear-gradient(to right, rgb(74, 154, 247), rgb(1, 101, 218));
    color: #fff !important;
}
.popup_top .profession .van-button--large {
    height: 50px;
}
/* 公告 */
.notice{
    z-index: 3;
}
.notice .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.notice .block_w{
    width: 90%;
    height: 24.375rem;
    background-color: #fff;
    border-radius: .5625rem;
    padding:.15625rem .84375rem;
    position: relative;
}
.notice .block_w .van-icon{
    position: absolute;
    right: 10px;
    top: 10px;
}
.notice h3{
    font: .9375rem/2.4375rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    margin: 0;
    border-bottom: 1px solid #b3b3b3;
}
.notice .wrap_c{
    overflow-y: auto;
    max-height: 16rem;
    height: 16rem;
    padding: 2% 0;
    text-align: left;
}
.notice button{
    height: 40px;
    font-size: 1rem;
}
.notice .van-checkbox{
    justify-content: center;
    margin: 3% 0;
}
.notice .van-checkbox__label{
    font-size: 14px;
}
</style>
<style>
.public_tabs  .van-tabs__wrap {
    height: 81px;
}
.public_tabs .van-tab__text{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.public_tabs .van-tab--active .public_title_active{
    display: block;
}
.public_tabs .van-tab--active .public_title{
    display: none;
}
.public_tabs .van-tabs__line {
    width: 25px;
    background-color: #ff4e00;
}
.popup_top .van-field__label{
    width: auto;
}
.progroup .van-col .van-checkbox__icon{
    display: none;
}
.progroup .van-col .van-checkbox__label{
    padding: 0 15px;
    height: 1.5625rem;
    font-size: 14px;
    line-height: 1.5625rem;
    /* background: linear-gradient(to right, rgb(74, 154, 247), rgb(1, 101, 218)); */
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #ebedf0;
}
.progroup .checked .van-checkbox__label{
    background: linear-gradient(to right, rgb(74, 154, 247), rgb(1, 101, 218));
    color: #fff;
}

.home_tab .van-tab {
    color: #313131;
}
.home_tab .van-tab--active {
    color: #ff4e00;
}
.van-tabs__line {
    background-color: #ff4e00;
}
.home_top_swiper .van-search {
    padding: 6px 12px;
}
.home_top_swiper .van-search .van-cell {
    padding: 2px 8px 2px 0;
}
</style>